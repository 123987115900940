import * as React from 'react'
import styled from '@emotion/styled'
import { Script } from "gatsby"

import Layout from '../components/layout'
import {Seo} from '../components/seo'

// import ResponsiveEmbed from "react-responsive-embed"

import {HeroRewards} from '../components/heros/HeroRewards'
import {SkipUberDelivery} from '../components/SkipUberLogos'

const RewardsPage = () => {
  const Fc = styled.div`
    margin-bottom: var(--space-4);

    h1 {
      margin-bottom: var(--space-1);
    }

    p {
      margin-top: 0;
      padding-top: 0;
    }

    .iframe {
      border: none;
      width: 100%;
      height: 100%;
    }
  `

  const IFrameContainer = styled.div`
    margin-top: var(--space-4);
    margin-bottom: var(--space-4);

    .iframe {
      //min-height: 800px;
      width: 100%;
      border: none;
    }
  `
  return (
      <Layout>
      <HeroRewards />
      <Fc>
        <h1>
          REWARDS PROGRAM
        </h1>
        <p className='blueFont textCenter'>
          Sign up now to be the first to see our new and updated products
        </p>
      </Fc>
      <IFrameContainer>
        <iframe
      src="https://link.rawmedia.ca/widget/form/IFu42YTLTDriH5sIndu5"
      style={{minWidth: "100%", height: "100%", border: "none", borderRadius: "4px"}}
      id="inline-IFu42YTLTDriH5sIndu5" 
      data-layout="{'id':'INLINE'}"
      data-trigger-type="alwaysShow"
      data-trigger-value=""
      data-activation-type="alwaysActivated"
      data-activation-value=""
      data-deactivation-type="neverDeactivate"
      data-deactivation-value=""
      data-form-name="Rewards Program"
      data-height="924"
      data-layout-iframe-id="inline-IFu42YTLTDriH5sIndu5"
      data-form-id="IFu42YTLTDriH5sIndu5"
      title="Rewards Program"
    />
<Script src="https://link.rawmedia.ca/js/form_embed.js" />
      </IFrameContainer>
      <SkipUberDelivery />
    </Layout>
  )
}

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => {
  return (
      <>
      <Seo title='Coal Harbour Liquor Rewards Page'
           description='Sign up to our Rewards page today!'
           canonical="https://coalharbourliquorstore.com/rewards/" />
      <script src='https://link.rawmedia.ca/js/form_embed.js' />
    </>
  )
}

export default RewardsPage
